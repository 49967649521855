
.library-wrapper{
    display: flex;
    flex-direction: column;
    height: 100%;
    .library-wrapper-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .top-title{
            color: #444;
            font-size: 18px;
        }
        .btn-blue {
            width:80px;
            background: #574DED;
            border-radius: 4px;
            color: #fff;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
        }
    }
    .library-container{
        display: flex;
        flex-direction: column;
        background: #fff;
        margin-top: 20px;
        border-radius: 10px;
        padding: 30px 26px;
        box-shadow: 0 0 17px 1px rgba(11, 12, 28, 0.0);
        height: calc(100vh - 212px);
        .library-list-scrollbar{
            height: 100%;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
        .library-list{
            display: flex;
            flex-wrap: wrap;
            .list-item{
                border: 1px solid #DCDCDC;
                border-radius: 4px;
                margin: 0 20px 20px 0;
                /*width: calc(20% - 20px);*/
                width: 300px;
                .list-item-top{
                    display: flex;
                    justify-content: space-between;
                    padding: 10px;
                    box-sizing: border-box;
                    .list-item-tile{
                        font-size: 16px;
                        color: #444;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                    }
                    i{
                        height: 20px;
                        width: 20px;
                        line-height: 20px;
                        font-size: 16px;
                        cursor: pointer;
                        &:hover{
                            color:#fE4800;
                        }
                    }
                }
                .list-item-center{
                    padding: 10px;
                    box-sizing: border-box;
                    height: 135px;
                    .goods-num{
                        font-size: 14px;
                        color: #666;

                        em{
                            color:#fE4800;
                            font-style:normal
                        }
                    }
                    .list-item-goods-box{
                        display: flex;
                        .list-item-goods-img{
                            width: 56px;
                            height: 56px;
                            margin: 20px 18px 20px 0;
                            img{
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                    }
                    .null-goods{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 96px;
                        span{
                            background: #EDEDF0;
                            border-radius: 2px;
                            width: 100px;
                            height: 40px;
                            line-height: 40px;

                        }
                    }
                }
                .list-item-bottom{
                    height: 50px;
                    line-height: 50px;
                    .btn-title{
                        text-align: center;
                        font-size: 14px;
                        color: #444;
                        cursor: pointer;

                        &:hover{
                            background: #FE4800;
                            color: #fff;
                        }
                    }
                    .disabled{
                        text-align: center;
                        color: #999;
                        cursor: not-allowed;
                    }
                }
                &:nth-of-type(5n){
                    margin-right: 0;
                }
            }
        }
        .nll-data{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    }
    .next-dialog-body{
        .color-brand{
            color: #FE4800;
        }
    }

    .dialog-footer{
        text-align: center;
        .color-brand-btn {
            color: #FFF;
            background-color: #FE4800;
            border-color: #FE4800;
            &:hover,&:focus{
                background: #ff822e;
                border-color: #ff822e;
                color: #FFF;
            }
        }
    }
}
